import * as amplitude from "@amplitude/analytics-browser";
import { version } from "../../package.json";

amplitude.init(import.meta.env.VITE_APP_AMPLITUDE_API_KEY, {
  appVersion: version,
});

const segment = window.analytics;

export const trackEvent = (key: string, value: object) => {
  segment?.track(key, value);
  amplitude?.track(key, value);
};

export { amplitude };
