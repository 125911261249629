import { Link, useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { tokenState } from "state";
import { BASE_URL, cls, api, marketUrl } from "../../utils/util";
import AlarmHeader from "./AlarmHeader";
import { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { useQuery } from "react-query";
import { headerColorState } from "state";
import { EDU_ROUTE, CLASS_ROUTE } from "App";
import HeaderFlag from "./HeaderFlag";

import WingBanner from "component/advertisment/banner";
import { isMarketOnlyUser, myPageUrl } from "utils/marketUtil";
import Logo, { ChicruitingLogo } from "../Logo";
import LogoAndSearchBar from "./LogoAndSearchBar";
import useHeaderTabs from "hooks/useHeaderTabs";
import HeaderSubTab from "./HeaderSubTab";

const Header = ({ current }) => {
  const token = useRecoilValue(tokenState);
  const location = useLocation();
  const navigate = useNavigate();
  const headerColor = useRecoilValue(headerColorState);
  const user = token && token.user_type;
  const [scrolled, setScrolled] = useState(window.scrollY > 60);
  const [isOpenSubTab, setIsOpenSubTab] = useState(false);
  const [hoveredTabUrl, setHoveredTabUrl] = useState("");

  const tabs = useHeaderTabs();
  const handleTabHover = (url) => {
    setHoveredTabUrl(url);
    setIsOpenSubTab(true);
  };

  const { data: userCount, isLoading: userCountLoading } = useQuery(
    "utilUserCount",
    () => axios.get(`${BASE_URL}/util/user_count`).then((res) => res.data),
    { cacheTime: Infinity, staleTime: 15 * 60 * 1000 },
  );

  const filteredTabs = tabs.filter(({ value }) => !isMarketOnlyUser(user) || value === "market");

  const currentHasSubTab = tabs.find(
    (tab) =>
      current === tab.value &&
      tab.value !== "expo" &&
      (tab.subTabs.length > 0 || tab.value === "board"),
  );

  const onPageScroll = () => {
    setScrolled(window.scrollY > 60);
  };

  useEffect(() => {
    window.addEventListener("scroll", onPageScroll, false);
    return () => {
      window.removeEventListener("scroll", onPageScroll, false);
    };
  }, []);

  const isShowHeaderWingBanner = useMemo(() => {
    const locationPath = location.pathname.split("/")[1];
    return !["market"].includes(locationPath);
  }, [location.pathname]);

  return (
    <section className={cls(scrolled ? (currentHasSubTab ? "pb-[155px]" : "pb-[120px]") : "")}>
      <header
        className={cls(
          scrolled ? "fixed" : "relative",
          "top-0 left-0 right-0 flex flex-col z-[100] w-[1200px] mx-auto",
        )}
      >
        {isShowHeaderWingBanner && (
          <>
            <div
              className={cls(
                "2xl:flex hidden",
                "absolute flex flex-col gap-[12px] w-[120px]",
                scrolled ? "top-[56px] left-[-140px]" : "top-[114px] left-[-140px]",
              )}
            >
              <WingBanner name="pc_all_wing_left_top" page="all" />
              <WingBanner name="pc_all_wing_left_middle" page="all" />
              <WingBanner name="pc_all_wing_left_bottom" page="all" />
            </div>
            <div
              className={cls(
                "2xl:flex hidden",
                "absolute flex flex-col gap-[12px] w-[120px]",
                scrolled ? "top-[56px] right-[-140px]" : "top-[114px] right-[-140px]",
              )}
            >
              <WingBanner name="pc_all_wing_right_top" page="all" />
              <WingBanner name="pc_all_wing_right_middle" page="all" />
              <WingBanner name="pc_all_wing_right_bottom" page="all" />
            </div>
          </>
        )}{" "}
        <LogoAndSearchBar scrolled={scrolled} />
        <div
          className={cls(
            !scrolled && currentHasSubTab ? "border-b-[1px] h-[95px]" : "",
            "border-solid border-gray-200 border-t-[1px] border-opacity-70",
          )}
        >
          <nav
            className={cls(
              scrolled ? "bg-white border-b-[1px]" : "",
              currentHasSubTab ? "border-b-[1px]" : "",
              "border-solid border-gray-300 border-opacity-70",
            )}
            onMouseLeave={() => setIsOpenSubTab(false)}
          >
            <div className="min-w-[1200px] w-full grid grid-cols-[repeat(auto-fit,minmax(50px,1fr))]">
              {filteredTabs?.map((tab, index) => {
                const isSubtab =
                  !scrolled &&
                  tab.subTabs.length > 0 &&
                  current === tab.value &&
                  tab.value !== "expo";
                return (
                  <ul id={tab.value} className="relative" key={tab.label}>
                    <TabItem
                      current={current}
                      tab={tab}
                      color={scrolled ? null : headerColor}
                      scrolled={scrolled}
                      onHover={() => handleTabHover(tab.url)}
                    >
                      {isSubtab && (
                        <nav className="absolute w-0 flex text-gray-600">
                          {tab.subTabs.map((subTab) => {
                            return (
                              <ul
                                className="relative px-4 first:pl-0 whitespace-nowrap"
                                key={subTab.cate_name}
                              >
                                <SubTabItem
                                  pathname={location.pathname}
                                  tab={subTab}
                                  color={scrolled ? null : headerColor}
                                />
                              </ul>
                            );
                          })}
                        </nav>
                      )}
                    </TabItem>
                  </ul>
                );
              })}
            </div>
          </nav>
          {isOpenSubTab && location.pathname !== hoveredTabUrl && (
            <HeaderSubTab tabs={tabs} setIsOpenSubTab={setIsOpenSubTab} />
          )}
        </div>
      </header>
    </section>
  );
};

export default Header;

const TabItem = ({ current, tab, children, color, scrolled, onHover }) => {
  return (
    <>
      <div className="flex items-center px-[16px]" onMouseEnter={() => onHover()}>
        <Link
          className={cls(
            color ? `text-${color}` : "",
            current === tab.value
              ? color
                ? "text-opacity-100 font-bold"
                : "text-primary-800 font-medium"
              : color
              ? "text-opacity-50 font-medium hover:text-opacity-100 hover:font-bold"
              : "font-medium hover:text-primary-800",
            "text-[14px] py-4 flex items-center relative",
          )}
          to={tab.url}
        >
          {!scrolled && <HeaderFlag value={tab.value} />}
          {tab.value === "chicruiting" ? <ChicruitingLogo width={74} color={color} /> : tab.label}
          {tab.label === "Q&A" && (
            <div className="h-[15px] ml-1 bg-gradient-to-r from-[#6533E8] to-[#607EE1] px-[3px] text-white rounded-sm flex items-center">
              <span className="w-[10px] h-[10px] bg-[url('/img/ai/icon-badge.svg')] bg-no-repeat bg-center bg-cover"></span>
              <span className="!text-[#fff] text-[11px] font-bold !bg-transparent">AI</span>
            </div>
          )}
        </Link>
      </div>
      {children}
    </>
  );
};

const SubTabItem = ({ pathname, tab, color }) => {
  // url이 /management/seminar_weekly일 때 /management/seminar도 하이라이트 되는 문제 해결
  const seminarException =
    pathname.includes("/management/seminar_weekly") && tab.url === "/management/seminar";

  // url이 /recruit이거나 /recruit/chicruiting일 때 치과위생사가 하이라이트 되도록 함
  const recruitException =
    (pathname === "/recruit" && tab.url === "/recruit/hygienist") ||
    (pathname.includes("/recruit/chicruiting") && tab.url === "/recruit/hygienist");

  const location = useLocation();
  const urlParam = new URLSearchParams(location.search);
  const board = parseInt(urlParam.get("cate")) || 0;

  const LinkClassName = () => {
    const className = "text-[14px] py-[9px] flex items-center ";

    if (color) {
      if ((pathname.includes(tab.url) && !seminarException) || recruitException) {
        return (
          className +
          `text-${color} text-opacity-100 font-bold border-b-[3px] border-solid border-${color}`
        );
      } else {
        return className + `text-${color} text-opacity-50 hover:text-opacity-100`;
      }
    } else if (board === tab.cate_cd) {
      return className + "text-primary-800 border-b-[3px] border-solid border-primary-800";
    } else {
      if ((pathname.includes(tab.url) && !seminarException) || recruitException) {
        return (
          className + "text-primary-800 font-medium border-b-[3px] border-solid border-primary-800"
        );
      } else {
        return className + "text-gray-600 hover:text-primary-800";
      }
    }
  };

  return (
    <>
      <Link className={LinkClassName()} to={tab.url}>
        {tab.cate_name === "AI 임상검색" && (
          <span className="w-4 h-4 mr-1 bg-[url('/img/ai/icon-badge-gradient.svg')] bg-no-repeat bg-center bg-cover"></span>
        )}
        {tab.cate_name}
      </Link>
    </>
  );
};
