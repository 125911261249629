import { SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import { trackEvent } from "utils/analytics";

const HeaderSubTabTest = ({
  tabs,
  setIsOpenSubTab,
}: {
  tabs: any;
  setIsOpenSubTab: React.Dispatch<SetStateAction<boolean>>;
}) => {
  const navigate = useNavigate();
  const subtabs = tabs?.map((tab: any) => {
    return { [tab.value]: tab.subTabs };
  });
  const expoLength = subtabs.find((item: any) => "expo" in item)?.expo?.length;

  const linkToSubTab = (subTab: any) => {
    subTab.cate_cd ? navigate(`/post?cate=${subTab.cate_cd}`) : navigate(subTab.url);
    setIsOpenSubTab(false);
  };

  const marginTop = expoLength > 10 ? `-${expoLength * 15}px` : `-${expoLength * 10}px`;

  return (
    <nav
      className="absolute w-screen ml-[calc(50%-50vw)] mx-auto bg-white z-50 shadow-header pt-4 pb-6 animated-header"
      onMouseEnter={() => setIsOpenSubTab(true)}
      onMouseLeave={() => setIsOpenSubTab(false)}
    >
      <div className="max-w-[1200px] mx-auto">
        <div className="flex text-sm">
          {subtabs.map((section: any) => {
            const sectionKey = Object.keys(section)[0];
            const items = section[sectionKey];
            if (!items || items.length === 0) return <div className="w-1/12"></div>;

            return (
              <div
                key={sectionKey}
                className="px-4 w-1/12 flex-shrink-0"
                onClick={() => trackEvent("Hover Sub Tab Click", { type: sectionKey })}
              >
                <div className="space-y-3 text-gray-600">
                  {items.map((item: any, index: number) => (
                    <div
                      key={`${sectionKey}-${index}`}
                      className="whitespace-nowrap text-secondary-900 font-normal text-sm leading-5 cursor-pointer hover:text-primary-800"
                      onClick={() => linkToSubTab(item)}
                    >
                      {item.cate_name === "AI 임상검색" ? (
                        <div className="flex items-center">
                          <img src="/img/ai/icon-badge-gradient.svg" className="w-4 h-4 mr-1" />
                          {item.cate_name}
                        </div>
                      ) : (
                        item.cate_name
                      )}
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
        {/* Promotional Banner */}
        <div
          onClick={() => {
            trackEvent("Banner Click", { origin: "hover-menu" });
            window.location.href = "https://moreden.co.kr/chicruiting/company/people";
          }}
          className="w-[380px] mx-auto"
          style={{ marginTop: marginTop }}
        >
          <img src="/img/hover-menu-banner.png" className="rounded-xl" />
        </div>
      </div>
    </nav>
  );
};

export default HeaderSubTabTest;
