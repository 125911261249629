import Logo from "component/Logo";
import { Link, useNavigate } from "react-router-dom";
import { isMarketOnlyUser, myPageUrl } from "utils/marketUtil";
import { cls } from "utils/util";
import AlarmHeader from "./AlarmHeader";
import { useState } from "react";
import { useRecoilValue } from "recoil";
import { tokenState, headerColorState } from "state";

const LogoAndSearchBar = ({ scrolled }: { scrolled: any }) => {
  const token = useRecoilValue(tokenState);
  const navigate = useNavigate();
  const headerColor = useRecoilValue(headerColorState);
  const user = token && token.user_type;
  const [searchKeyword, setSearchKeyword] = useState("");

  return (
    <div
      className={cls(
        scrolled ? "hidden" : "w-[1200px] mx-auto flex justify-between items-center h-[60px]",
      )}
    >
      <Link to="/">
        <Logo color={headerColor || ""} />
      </Link>
      <div className="flex items-center mt-3 mr-3 gap-4">
        <form
          className="text-[14px] relative"
          onSubmit={(e) => {
            e.preventDefault();
            const q = searchKeyword.trim();
            navigate(`/search?indexUid=all&q=${q}`);
            setSearchKeyword("");
          }}
        >
          <i className="ii ii-search absolute left-[1px] px-2.5 h-full flex items-center leading-[24px] text-[24px] text-gray-800"></i>
          <input
            type="search"
            className="w-[320px] h-[40px] pl-[44px] py-2 rounded-md bg-gray-50 border border-solid border-secondary-200 placeholder-primary-800 focus:ring-1 focus:ring-primary-800 search-cancel:appearance-none focus:placeholder-opacity-0"
            value={searchKeyword}
            onChange={(e) => setSearchKeyword(e.target.value)}
            placeholder={'"공동구매"를 검색해 보세요'}
          />
          {searchKeyword && (
            <i
              className="ii ii-remove-circle h-full leading-[20px] text-[20px] text-gray-800 absolute top-0 right-[1px] cursor-pointer px-2.5 flex items-center"
              onClick={() => setSearchKeyword("")}
            ></i>
          )}
        </form>
        <div className="flex items-center gap-2">
          {user && !isMarketOnlyUser(user) && (
            <AlarmHeader>
              <i
                className={cls(
                  headerColor
                    ? `text-${headerColor} hover:bg-white/10`
                    : "text-gray-600 hover:bg-gray-100",
                  "ii ii-bell-outline text-[28px] w-[40px] h-[40px] flex justify-center items-center rounded-full",
                )}
              />
            </AlarmHeader>
          )}
          <Link to={myPageUrl(token?.user_type)}>
            <i
              className={cls(
                headerColor
                  ? `text-${headerColor} hover:bg-white/10`
                  : "text-gray-600 hover:bg-gray-100",
                "ii ii-user-outline text-[28px] w-[40px] h-[40px] flex justify-center items-center rounded-full",
              )}
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LogoAndSearchBar;
